export function uomForPart(part) {
  return part ? part.standard_uom : "?";
}

export function isEmpty(obj) {
  for(var key in obj) {
    if(obj.hasOwnProperty(key))
      return false;
  }
  return true;
}