import React from "react"
import PropTypes from "prop-types"
import { hot } from "react-hot-loader"
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import QueryString from 'query-string'
import ClipboardImage from 'images/copy.svg'

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: 'rgb(206, 212, 218)',
    '&:hover': { borderColor: 'rgb(206, 212, 218)' },
  })
};

const smallStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: 'rgb(206, 212, 218)',
    '&:hover': { borderColor: 'rgb(206, 212, 218)' },
    minHeight: "31px",
    padding: 0,
    fontSize: "14px",
    height: "31px",
    maxHeight: "31px"
  }),
  input: provided => ({
    ...provided,
    padding: 0
  }),
  singleValue: provided => ({
    ...provided
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: "31px"
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: "5px"
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: "5px"
  })
};

class BasicSelector extends React.Component {
  constructor(props) {
    super(props);

    if (props.selected_id) {
      this.state = {
        selectedOption: { value: props.selected_id, label: props.value}
      };
    } else if (props.selectedOption) {
      this.state = {
        selectedOption: props.selectedOption
      };
    } else {
      this.state = {}
    }
  }

  loadOptions = (inputValue, callback) => {
    this.getSuggestions(inputValue).then(response => {
      callback(response.data.map(suggestion => {
        return ({
          value: suggestion.id,
          label: suggestion.name,
          suggestion: suggestion
        })
      }));
    });
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    var additionalSearchParams;
    if (this.props.additionalSearchParams) {
      if (typeof this.props.additionalSearchParams === 'object') {
        additionalSearchParams = {};
        additionalSearchParams[this.props.additionalSearchParams.key] = document.querySelector(this.props.additionalSearchParams.querySelector).value
      }
      else {
        additionalSearchParams = this.props.additionalSearchParams();
      }
    } else {
      additionalSearchParams = {};
    }

    const url = this.props.searchUrl +
      '?' +
      QueryString.stringify({
        ...additionalSearchParams,
        search: inputValue
      });


    return fetch(url, { credentials: 'include' })
      .then(response => response.json());
  };

  onChange = (selectedOption) => {
    this.setState({ selectedOption });

    if (this.props.onChange) {
      if (typeof this.props.onChange === 'string') {
        window[this.props.onChange](selectedOption, this.props.onChangeOptions || {});
      } else {
        this.props.onChange(selectedOption);
      }
    }
  };

  copy = () => {
    navigator.clipboard.writeText(this.state.selectedOption.label)
  }

  render() {
    const selectProps = {
      classNamePrefix: "Select",
      className: this.props.className,
      name: this.props.name,
      placeholder: "Search...",
      value: this.state.selectedOption,
      styles: smallStyles,
      onChange: this.onChange,
      cacheOptions: false,
      loadOptions: this.loadOptions,
      isClearable: true,
      noOptionsMessage: (obj) => { return obj.inputValue == "" ? null : "No matches found"; }
    };

    return (
      <div className="form-control-mb basic_selector">
        <div className="row">
          <div className="col pe-0">
            {
              this.props.allowCreate
                ? <AsyncCreatableSelect {...selectProps} />
                : <AsyncSelect {...selectProps} />
            }
          </div>
          <div className="col-md-auto ps-0">
            <button type="button" className="btn btn-sm btn-link" onClick={this.copy}>
              <img src={ClipboardImage} alt="Copy" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

BasicSelector.propTypes = {
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  searchUrl: PropTypes.string.isRequired,
  selectedOption: PropTypes.object,
  selected_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
  small: PropTypes.bool,
  additionalSearchParams: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  allowCreate: PropTypes.bool,
  onChangeOptions: PropTypes.object
};

export default hot(module)(BasicSelector);
