import React from "react"
import BasicSelector from "./BasicSelector"

export default class PartDashboardSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null
    };
  }

  setSelected = (data) => {
    const url = new URL(window.location.href.split('?')[0])
    url.searchParams.set('standard_part_id', data.value)
    window.location.href = url.href
  }

  render() {
    return (
      <BasicSelector
        name="part_search"
        onChange={this.setSelected}
        searchUrl={this.props.searchUrl}
      />
    )
  }
}
