import React from "react"
import PropTypes from "prop-types"
import { hot } from "react-hot-loader"

class SelectOrLegacy extends React.Component {
  constructor(props) {
    super(props);
  }

  inputTag(attribute_name, human_name, value, args = {}) {
    var prefix = "";

    return (
      <div className="form-group mb-0">
        <label htmlFor={attribute_name} className="col-form-label-sm mb-0">{human_name}</label>
        <input
          name={attribute_name}
          className={"form-control form-control-sm " + attribute_name}
          type="text"
          value={value}
          disabled="disabled" />
      </div>
    );
  }

  render() {
    const options = [<option key="blank" value=""></option>].concat(this.props.options.map(option => <option key={option} value={option}>{option}</option>));

    if (this.props.value === "" || this.props.options.includes(this.props.value)) {
      return (
        <div className="form-group mb-0">
          <label htmlFor={this.props.name} className="col-form-label-sm mb-0">{this.props.humanName}</label>

          <select
            name={this.props.name}
            className={this.props.name + " form-control form-control-sm w-auto"}
            defaultValue={this.props.value}
            onChange={this.props.onChange}
          >
            {options}
          </select>
        </div>
      );
    } else {
      return (
        <div className="form-group mb-0">
          {this.inputTag(this.props.name, this.props.humanName, this.props.value)}
        </div>
      );
    }

  }
}

SelectOrLegacy.propTypes = {
  name: PropTypes.string.isRequired,
  humanName: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  options: PropTypes.array.isRequired
};

export default hot(module)(SelectOrLegacy);