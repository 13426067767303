import React from "react"
import PropTypes from "prop-types"
import { hot } from "react-hot-loader"

class InventoryReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      didError: false,
      tw_po_quantity_outstanding: {},
      pa_quantity: {},
      il_quantity: {},
      last_price: ""
    };
  }

  componentDidMount() {
    if (this.props.standardPartId) {
      this.fetchInventoryReport();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.standardPartId && this.props.standardPartId != prevProps.standardPartId) {
      this.fetchInventoryReport();
    }
  }

  fetchInventoryReport() {
    this.setState({ isLoading: true });

    fetch(this.props.baseUrl + "/standard_parts/" + this.props.standardPartId + "/report.json")
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          this.setState({
            isLoading: false,
            didError: true
          });
        }
      })
      .then(data => {
        this.setState({
          ...data,
          isLoading: false
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    if (this.props.error) {
      return (<div>Error</div>);
    }

    if (this.props.standardPartId) {
      if (this.state.isLoading) {
        return (
          <div className="inventory_report pt-2 ps-2">Loading...</div>
        );
      } else if (this.state.didError) {
        return (
          <div className="inventory_report pt-2 ps-2">Cannot display inventory report.<br />Make sure inventory is in proper units of measure (not pallets!)</div>
        );
      } else {
        return (
          <div className="inventory_report pt-2 ps-2">
            <div className="row">
              <div className="col">Open TW POs:</div>
              <div className="col">{this.state.tw_po_quantity_outstanding.value} {this.state.tw_po_quantity_outstanding.uom}</div>
            </div>
            <div className="row">
              <div className="col">PA Inventory:</div>
              <div className="col">{this.state.pa_quantity.value} {this.state.pa_quantity.uom}</div>
            </div>
            <div className="row">
              <div className="col">IL Inventory:</div>
              <div className="col">{this.state.il_quantity.value} {this.state.il_quantity.uom}</div>
            </div>
            <div className="row">
              <div className="col">Last price:</div>
              <div className="col">{this.state.last_price || "None"}</div>
            </div>
          </div>
        );
      }
    } else {
      return (<div></div>);
    }
  }
}

InventoryReport.propTypes = {
  standardPartId: PropTypes.number.isRequired,
  baseUrl: PropTypes.string.isRequired
};

export default InventoryReport
