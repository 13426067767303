import React from 'react'
import ReactDOM from 'react-dom'
import BasicSelector from '../components/BasicSelector'

// Assuming conversion ratio
// These must match with StandardPart#uom_conversions
let uomDivisor = {
  'M Pieces': 1000,
  'C Pounds': 100,
}

function lineItemsEditor() {
  const lineItemsTables = document.querySelectorAll('.line-items-input')
  lineItemsTables.forEach(lineItemsTable => {
    if(lineItemsTable){
      const lineItems = document.getElementById(lineItemsTable.dataset.tag || 'line_items')
      let lineItemsData = JSON.parse(lineItems.value)

      const addUpdater = (cell) => {
        cell.addEventListener("input", (e) => {
          const index = e.target.closest('tr').dataset.lineItemIndex
          const attribute = e.target.getAttribute('name')

          const lineItemsTable = e.target.closest('table')
          const lineItems = document.getElementById(lineItemsTable.dataset.tag || 'line_items')
          let lineItemsData = JSON.parse(lineItems.value)

          if (index){
            if(e.target.type == 'select-one'){
              lineItemsData[index][attribute] = e.target.selectedOptions[0].value
            } else {
              let value = e.target.value
              if (e.target.classList.contains('price')){
                value = value.replaceAll(/(\$|,)/g, '')
              } else if (e.target.classList.contains('decimal')) {
                value = value.replaceAll(/,/g, '')
              } else if(e.target.classList.contains('decimal-fraction')){
                value = value.replaceAll(/,/g, '')
              }

              lineItemsData[index][attribute] = value
            }
          } else {
            const section = e.target.closest('tbody')
            const lineItemIndex = section.id.split('-')[1]
            const subtableAttribute = section.dataset.subtableAttribute
            const subtableLineItemIndex = e.target.closest('tr').dataset.subtableLineItemIndex

            lineItemsData[lineItemIndex][subtableAttribute][subtableLineItemIndex][attribute] = e.target.value
          }

          lineItems.value = JSON.stringify(lineItemsData)

          if (e.target.dataset.additionalCallback) {
            window[e.target.dataset.additionalCallback](e)
          }
        })
        cell.addEventListener('keydown', (e) => {
          if(e.key === 'Enter') {
            e.preventDefault()
            const targetRow = document.querySelector(`[data-line-item-index="${parseInt(e.target.closest('tr').dataset.lineItemIndex) + 1}"]`)
            if(targetRow){
              targetRow.querySelector('td input').focus()
            }
          } else if(e.key === 'Tab' && e.target.closest('tbody').id.includes('subtable')) {
            const allInputs = e.target.closest('tr').querySelectorAll('input')
            if(e.target == allInputs[allInputs.length - 1] ){
              e.preventDefault()
              e.target.closest('tbody').querySelector('.add-subtable-row').dispatchEvent(new Event('click'))
            }
          }
        })
      }

      lineItemsTable.querySelectorAll('input').forEach(addUpdater)
      lineItemsTable.querySelectorAll('select').forEach(addUpdater)

      window.lineItemSelectorChange = (data, { index, attribute, ref }) => {
        const lineItems = document.getElementById(ref || 'line_items')
        let lineItemsData = JSON.parse(lineItems.value)

        lineItemsData[index][attribute] = data.value
        lineItems.value = JSON.stringify(lineItemsData)
      }

      window.inventoryActionChange = (e) => {
        if (e.target.value === 'Create new'){
          expandInventory(e.target, false)
        } else if (e.target.value === 'Modify existing') {
          expandInventory(e.target, true)
        } else {
          hideInventory(e.target)
        }
      }

      window.updateQuantity = (e) => {
        try {
          const row = e.target.closest('tr')
          const quantityPerCarton = parseFloat(row.querySelector('#quantity_per_carton').value.replaceAll(',', ''))
          const totalCartons = parseFloat(row.querySelector('#total_cartons').value.replaceAll(',', ''))
          if (quantityPerCarton && totalCartons) {
            const qtyCell = row.querySelector('#quantity')
            const divisor = uomDivisor[row.querySelector('#quantity_uom').value || 'M Pieces']
            qtyCell.value = (quantityPerCarton * totalCartons) / divisor
            qtyCell.dispatchEvent(new Event('input'))
          }
        } catch(error) {
          console.log(e)
          console.log(error)
        }
      }

      const getNewTrClass = (lineItemsTable) => {
        const trs = (lineItemsTable || document).querySelectorAll('#line_items_table tbody:not(.d-none) tr:not(.d-none)')
        const lastTr = trs[trs.length - 1]

        if (!lastTr || lastTr.classList.contains('alternate')){
          return ''
        } else {
          return 'alternate'
        }
      }

      window.addEmptyRow = (tableSelector) => {
        const lineItemsTable = document.querySelector(tableSelector || '.line-items-input')
        const lineItems = document.getElementById(lineItemsTable.dataset.tag || 'line_items')
        let lineItemsData = JSON.parse(lineItems.value)

        const newTrClass = getNewTrClass(lineItemsTable)
        const templateTbody = lineItemsTable.querySelector('#template_tbody')
        const newTbody = templateTbody.cloneNode(true)
        lineItemsTable.insertBefore(newTbody, templateTbody)

        newTbody.classList.remove('d-none')
        newTbody.id = ''

        const newRow = newTbody.querySelector('tr')
        newRow.classList.remove('d-none')
        newRow.id = ''

        if(newTrClass.length > 1){
          newRow.classList.add(newTrClass)
        }

        const templateRow = templateTbody.querySelector('tr')
        const lastIndex = parseInt(templateRow.dataset.lineItemIndex)

        templateRow.dataset.lineItemIndex = parseInt(templateRow.dataset.lineItemIndex) + 1
        const templateSubtable = document.querySelector(`subtable-${lastIndex}`)

        const selector = newRow.querySelector('[data-react-class="BasicSelector"]')
        if (selector){
          const target = selector.parentElement
          const props = JSON.parse(selector.dataset.reactProps)
          props.onChangeOptions.index = lastIndex
          ReactDOM.render(<BasicSelector {...props} />, target)
        }

        if (templateSubtable) {
          const newSubtable = templateSubtable.cloneNode(true)
          lineItemsTable.insertBefore(newSubtable, templateTbody)

          newSubtable.classList.remove('d-none')
          newSubtable.id = `subtable-${templateRow.dataset.lineItemIndex}`
        }

        lineItemsData.push({index: parseInt(newRow.dataset.lineItemIndex) + 1, inventories: []})
        lineItems.value = JSON.stringify(lineItemsData)
        newRow.querySelectorAll('input').forEach(addUpdater)
        newRow.querySelectorAll('select').forEach(addUpdater)
      }

      window.removeRow = (btn, dataId) => {
        const lineItems = document.getElementById(dataId || 'line_items')
        let lineItemsData = JSON.parse(lineItems.value)

        const row = btn.closest('tr')
        const rowIndex = parseInt(row.dataset.lineItemIndex)
        if (lineItemsData[rowIndex].id){
          lineItemsData[rowIndex]['_destroy'] = true
        } else {
          lineItemsData[rowIndex] = {'_destroy': true}
        }
        lineItems.value = JSON.stringify(lineItemsData)
        row.remove()
      }

      window.addSubtableRow = (btn) => {
        const tbody = btn.closest('tbody')
        const tr = btn.closest('tr')
        const templateRow = tbody.querySelector('.template_subtable_line_item')
        const newRow = templateRow.cloneNode(true)
        const lineItemIndex = parseInt(tbody.id.split('-')[1])
        const subtableAttribute = tbody.dataset.subtableAttribute
        const lineItems = document.getElementById(lineItemsTable.dataset.tag || 'line_items')
        let lineItemsData = JSON.parse(lineItems.value)

        tbody.insertBefore(newRow, tr)

        newRow.classList.remove('d-none')
        newRow.classList.remove('template_subtable_line_item')

        templateRow.dataset.subtableLineItemIndex = parseInt(templateRow.dataset.subtableLineItemIndex) + 1

        if(lineItemsData[lineItemIndex][subtableAttribute] === undefined){
          lineItemsData[lineItemIndex][subtableAttribute] = []
        }

        lineItemsData[lineItemIndex][subtableAttribute].push(
          {index: parseInt(newRow.dataset.subtableLineItemIndex) + 1}
        )

        lineItems.value = JSON.stringify(lineItemsData)
        newRow.querySelectorAll('input').forEach(addUpdater)
        newRow.querySelectorAll('select').forEach(addUpdater)

        if(btn.dataset.allowFocus) {
          newRow.querySelector('input').focus()
        } else {
          btn.dataset.allowFocus = true
        }

        const cartonCount = newRow.querySelector('#carton_count')
        const originalCartonCount = newRow.querySelector('#original_carton_count')
        const totalCartons = document.querySelector(`[data-line-item-index="${lineItemIndex}"] #total_cartons`)?.value ||
          document.querySelector(`[data-line-item-index="${lineItemIndex}"] #carton_count`)?.value

        if(cartonCount && originalCartonCount && totalCartons) {
          let mainTotalCartons = parseFloat(totalCartons)

          if(lineItemsData[lineItemIndex][subtableAttribute].length > 1){
            lineItemsData[lineItemIndex][subtableAttribute].forEach(li => {
              if(li.original_carton_count) {
                mainTotalCartons = mainTotalCartons - parseFloat(li.original_carton_count)
              }
            })
          }

          cartonCount.value = mainTotalCartons
          originalCartonCount.value = mainTotalCartons

          cartonCount.dispatchEvent(new Event('input'))
          originalCartonCount.dispatchEvent(new Event('input'))
        }
      }

      window.removeSubtableRow = (btn) => {
        const row = btn.closest('tr')
        const tbody = btn.closest('tbody')
        const rowIndex = parseInt(row.dataset.subtableLineItemIndex)
        const lineItemIndex = parseInt(tbody.id.split('-')[1])
        const subtableAttribute = tbody.dataset.subtableAttribute
        const lineItems = document.getElementById(lineItemsTable.dataset.tag || 'line_items')
        let lineItemsData = JSON.parse(lineItems.value)

        lineItemsData[lineItemIndex][subtableAttribute].splice(rowIndex, 1)

        lineItems.value = JSON.stringify(lineItemsData)
        row.remove()
      }

      window.toggleInventory = (element) => {
        const row = element.closest('tr')
        const rowIndex = parseInt(row.dataset.lineItemIndex)
        const section = document.querySelector(`tbody#subtable-${rowIndex}`)

        if(section.classList.contains('d-none')) {
          section.classList.remove('d-none')
          if(section.querySelectorAll('tr').length < 6 && section.parentElement.dataset.disableSubtableByDefault !== 'true') {
            section.querySelector('.add-subtable-row').dispatchEvent(new Event('click'));
          }
        } else {
          section.classList.add('d-none')
        }
      }

      const expandInventory = (element, picked) => {
        const row = element.closest('tr')
        const rowIndex = parseInt(row.dataset.lineItemIndex)
        const section = document.querySelector(`tbody#subtable-${rowIndex}[data-subtable-attribute="${picked ? 'picked_inventories' : 'inventories'}"]`)

        section.classList.remove('d-none')

        const hideSection = document.querySelector(`tbody#subtable-${rowIndex}[data-subtable-attribute="${picked ? 'inventories' : 'picked_inventories'}"]`)
        hideSection.classList.add('d-none')

        if (section.querySelectorAll('tr').length < 6) {
          section.querySelector('.add-subtable-row')?.dispatchEvent(new Event('click'));
        }
      }

      const hideInventory = (element) => {
        const row = element.closest('tr')
        const rowIndex = parseInt(row.dataset.lineItemIndex)
        const sections = document.querySelectorAll(`tbody#subtable-${rowIndex}`)

        sections.forEach(section => section.classList.add('d-none'))
      }

      window.handleInventoryReport = (data, options) => {
        const lastPrice = document.querySelector(`[data-line-item-index="${options.index}"] .last_price`)
        const openTwPos = document.querySelector(`[data-line-item-index="${options.index}"] .open_tw_pos`)
        const paInventory = document.querySelector(`[data-line-item-index="${options.index}"] .pa_inventory`)
        const ilInventory = document.querySelector(`[data-line-item-index="${options.index}"] .il_inventory`)

        if(options.error){
          [lastPrice, openTwPos, paInventory, ilInventory].forEach(item => {
            item.innerText = 'Error'
          })
        } else {
          lastPrice.innerText = data.last_price
          openTwPos.innerText = `${data.tw_po_quantity_outstanding.value} ${data.tw_po_quantity_outstanding.uom}`
          paInventory.innerText = `${data.pa_quantity.value} ${data.pa_quantity.uom}`
          ilInventory.innerText = `${data.il_quantity.value} ${data.il_quantity.uom}`
        }
      }

      window.fetchInventoryReport = (standardPartId, index) => {
        fetch(`/standard_parts/${standardPartId}/report.json`)
          .then(response => {
            if (response.ok) {
              return response.json()
            }
          })
          .then(data => {
            if (data) {
              handleInventoryReport(data, { index })
            } else {
              handleInventoryReport(null, { index, error: true })
            }
          })
          .catch(error => console.log(error))
      }

      window.twLineItemSelectorChange = (data, { index, attribute, ref }) => {
        lineItemSelectorChange(data, { index, attribute, ref })
        if (!ref) {
          window.fetchInventoryReport(data.value, index)
        }
      }

      window.shippingReleaseLineItemSelectorChange = (data, { index, attribute }) => {
        const standardPartId = data.suggestion.standard_part.id

        lineItemSelectorChange(data, { index, attribute })
        lineItemSelectorChange({ value: standardPartId }, { index, attribute: 'standard_part_id' })
        window.fetchInventoryReport(standardPartId, index)
      }

      if (lineItemsTable.classList.contains('tw_po_line_items')){
        lineItemsData.forEach((lineItemData, i) => {
          if (lineItemData.standard_part_id){
            fetchInventoryReport(lineItemData.standard_part_id, i)
          }
        })
      }
    }
  })

  document.querySelectorAll(".expand-row").forEach(expander => {
    const closestTbody = expander.closest('tbody')

    if (
      !closestTbody.classList.contains('d-none') &&
      closestTbody.nextElementSibling.classList.contains('d-none') // prevent multiple events from closing it back
    ){
      expander.dispatchEvent(new Event('click'));
    }
  })

  document.querySelectorAll('input.price').forEach(input => {
    if (input.value){
      input.value = window.currencyFormatter.format(input.value.replaceAll(/(\$|,)/g, ''))
    }
  })

  document.querySelectorAll('input.decimal').forEach(input => {
    if (input.value){
      input.value = window.decimalFormatter.format(input.value.replaceAll(/,/g, ''))
    }
  })

  document.querySelectorAll('input.decimal-fraction').forEach(input => {
    if (input.value){
      input.value = window.decimalFractionFormatter.format(input.value.replaceAll(/,/g, ''))
    }
  })
}

window.currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 3
})

window.decimalFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
})

window.decimalFractionFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2
})

window.toggleColumns = () => {
  document.querySelectorAll('.allow-toggle').forEach(element => {
    if (element.classList.contains('d-none')){
      element.classList.remove('d-none')
    } else {
      element.classList.add('d-none')
    }
  })
}

window.updateCustomerReturnUrl = (selectedOption) => {
  window.location.href = `${window.location.pathname}?customer_po_id=${selectedOption.value}`
}

document.addEventListener("turbo:load", lineItemsEditor)
document.addEventListener("turbo:render", lineItemsEditor)
